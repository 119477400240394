import React from "react";
import "./GameBoy.css";
import Screen from "./Screen";
import { Container, Row, Col } from "react-bootstrap";
import Tetris from "./Tetris";
import castle from "../img/castle.png";

function GameBoy() {

  return (
    <div>
      <div id="container">
        <div className="leftspace" />
        <div className="middlespace">
          <Tetris></Tetris>
          <div class="gameboy" />

          <div className="positionscreen">
            <Screen />
          </div>
        </div>
        <div className="rightspace" />
      </div>
    </div>
  );
}
export default GameBoy;
