import React, { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
import { Transition } from "react-transition-group";
import PDF from "../files/Resume-1.pdf";

import "./GameButton.css";

function onResumeClick() {
  window.open(PDF);
}

function GameButton() {
  const [count = 1, setCount] = useState(1);
  const [stateLeft, setStateLeft] = useState(false);

  if (count > 3) {
    setCount(1);
  }

  if (count < 1) {
    setCount(3);
  }

  let linkpos;
  let linkText;
  let arrowpos;
  let resumepos;

  if (stateLeft == true) {
    resumepos = (
      <div>
        <div class="arrow4">◀</div>
      </div>
    );
    linkpos = (
      <a>
        <div class="link4" onClick={onResumeClick}></div>
      </a>
    );

    linkText = "Resume";

    console.log("left happened");
  } else {
    switch (count) {
      case 1:
        arrowpos = <div class="arrow1">◀</div>;
        linkpos = (
          <a href="#spacer-about">
            <div class="link1"></div>
          </a>
        );
        linkText = "About me";
        break;
      case 2:
        arrowpos = <div class="arrow2">◀</div>;
        linkpos = (
          <a href="#spacer-projects">
            <div class="link2"></div>
          </a>
        );
        linkText = "Projects";

        break;
      case 3:
        arrowpos = <div class="arrow3">◀</div>;
        linkpos = (
          <a href="#spacer-links">
            <div class="link3"></div>
          </a>
        );
        linkText = "Links";
        break;
      default:
        arrowpos = <div class="arrow1">◀</div>;
        linkpos = (
          <a href="#spacer-about">
            <div class="link1"></div>
          </a>
        );
        linkText = "About me";
    }
  }

  return (
    <div class="buttoncontainer">
      <div class="fade-buttons5">
        <div>{resumepos}</div>
        <div>{arrowpos}</div>
        <div>{linkpos}</div>
      </div>
      <div class="fade-buttons">
        <div class="uppos">
          <div class="upbutton" onClick={() => setCount(count - 1)}></div>
        </div>
      </div>
      <div class="fade-buttons3">
        {" "}
        <div class="downpos">
          <div class="downbutton" onClick={() => setCount(count + 1)}></div>
        </div>
      </div>
      <div class="fade-in">
        <div class="currentlink">
          <p>▶ {linkText}</p>
        </div>
      </div>
      <div class="fade-buttons2">
        {" "}
        <div class="leftpos">
          <div class="leftbutton" onClick={() => setStateLeft(true)}></div>
        </div>
      </div>{" "}
      <div class="fade-buttons4">
        <div class="rightpos">
          <div class="rightbutton" onClick={() => setStateLeft(false)}></div>
        </div>
      </div>
      <div class="arrowtop">{resumepos}</div>
      <div class="arrowbot">{resumepos}</div>
      {/* <p>Count: {count}</p> */}
    </div>
  );
}

export default GameButton;
