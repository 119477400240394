import "./LogoSlide.css";
import { Container, Col, Row } from "react-bootstrap";
import cdt from "../img/cdtlogo.png";
import ncl from "../img/ncllogo.png";

import html from "../img/glogohtml.png";
import js from "../img/glogojs.png";
import css from "../img/glogocss.png";

import cpp from "../img/glogocpp.png";
import csh from "../img/glogocsharp.png";
import clogo from "../img/glogoc.png";
import py from "../img/glogopy.png";
import java from "../img/glogojava.png";

import rlogo from "../img/logoreact.png";
import git from "../img/logogit.png";
import aws from "../img/logoaws.png";

function LogoSlide() {
  return (
    <div class="slider">
      <div class="slide-track">
        <div class="slide">
          <img class="img-slide" src={html} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={js} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={css} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={cpp} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={csh} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={clogo} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={py} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={java} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={rlogo} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={git} alt="" />
        </div>
        <div class="slide">
          <img class="img-slide" src={aws} alt="" />
        </div>
      </div>
    </div>
  );
}
export default LogoSlide;
