import GameBoy from "./GameBoy";
import "./ContentRows.css";
import AboutBox from "./AboutBox";
import ProjectBox from "./ProjectBox";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import LinkCards from "./LinkCards";
import castle from "../img/nohuecastle.png";
import ArtCorner from "./ArtCorner";

function ContentRows() {
  return (
    <div class="content-wrapper">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Row>
              <Col>
                <div id="top">
                  <GameBoy />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div id="spacer-about"></div>
              </Col>
            </Row>

            <Row>
              <Col>
                <AboutBox />
              </Col>
            </Row>

            <div class="header-projects">
              <div class="project-text">. / P r o j e c t s</div>
            </div>

            <Container fluid>
              <Row>
                <Col xs={12}>
                  <ProjectBox></ProjectBox>
                </Col>
              </Row>
            </Container>

            <Col>
              <Row>
                <div id="spacer-links"></div>
              </Row>
            </Col>

            <div class="header-projects">
              <div class="project-text">. / Artwork</div>
            </div>

            <Row>
              {" "}
              <div id="artcorner">
                <ArtCorner />
              </div>
            </Row>

            <Col></Col>
            <Row>
              <div id="links"></div>
            </Row>

            <Row>
              <div id="bottom">
                <Container>
                  <Row>
                    <Col >
                      <div class="mailing-container">
                        {" "}
                        <LinkCards />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ContentRows;
