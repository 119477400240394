import "./ArtCorner.css";
import React, { useState } from "react";

import { Container, Row, Col, Carousel } from "react-bootstrap";

import castle from "../img/castle.png";

import crab from "../img/art/crab-small.png";
import fish from "../img/art/fish-small.png";
import turtle from "../img/art/turtle-small.png";
import laika from "../img/art/laika-small.png";
import slime from "../img/art/slime-small.png";
import slime2 from "../img/art/slime2-small.png";
import snail from "../img/art/snail-small.png";
import lobster from "../img/art/lobster-small.png";
import portrait from "../img/art/minimalist-small.png";

function ArtCorner() {
  return (
    <div>
      <div class="art-container">
        <Row>
          {" "}
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={slime} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={laika} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={slime2} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={crab} />
              </div>
            </div>
          </Col>
          <Col>
            {" "}
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={portrait} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={turtle} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={snail} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={fish} />
              </div>
            </div>
          </Col>
          <Col>
            <div class="spacing-img">
              {" "}
              <div class="single-img">
                <img src={lobster} />
              </div>
            </div>
          </Col>

        </Row>
      </div>
    </div>
  );
}

export default ArtCorner;
