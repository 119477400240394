import "./CourseCard.css";
import { Container, Row, Col } from "react-bootstrap";


function CourseCard() {
  return (
    <div>
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Operating Systems</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">C++</div>
                      <div class="stat-item">C</div>
                      <div class="stat-item">AGILE</div>
                    </div>
                  </Row>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Adv. 3D Game Programing</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">C#</div>
                      <div class="stat-item">AGILE</div>
                    </div>
                  </Row>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Algorithms Analysis</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">Java</div>
                    </div>
                  </Row>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Computer Security</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      {" "}
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">C#</div>
                      <div class="stat-item">Metasploit</div>
                      <div class="stat-item">Encryption</div>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Wireless Networks</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">C++</div>
                      <div class="stat-item">Python</div>
                    </div>
                  </Row>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Data Structures</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">C++</div>
                      <div class="stat-item">Java</div>
                    </div>
                  </Row>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <div class="coursecard-container">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div class="cardbody">
                <Row>
                  <div class="topsec">
                    <div class="cardname">Systems Programming</div>
                  </div>
                </Row>
                <div class="botsec">
                  <Row xs="auto">
                    <div class="cardstats">
                      <div class="stat-item">Linux</div>
                      <div class="stat-item">C++</div>
                      <div class="stat-item">Python</div>
                    </div>
                  </Row>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CourseCard;
