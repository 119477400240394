import "./SkillBars.css";

function SkillBars() {
  return (
    <div>
        
        <div class="skill-position"></div>
      <div class="container">
        <div class="skill-container">
          <div class="name-holder">HTML</div>

          <div class="skill html">
            <div class="percent-holder"></div>
          </div>
        </div>

        <div class="skill-position"></div>

        <div class="skill-container">
          <div class="name-holder">CSS</div>

          <div class="skill css">
            <div class="percent-holder"></div>
          </div>
        </div>

        <div class="skill-position"></div>

        <div class="skill-container">
          <div class="name-holder">JavaScript</div>

          <div class="skill js">
            {" "}
            <div class="percent-holder"></div>
          </div>
        </div>

        <div class="skill-position"></div>

        <div class="skill-container">
          <div class="name-holder">Python</div>

          <div class="skill python">
            <div class="percent-holder"></div>
          </div>
        </div>

        <div class="skill-position"></div>

        <div class="skill-container">
          <div class="name-holder">C/C++</div>

          <div class="skill ccpp">
            <div class="percent-holder"></div>
          </div>
        </div>

        <div class="skill-position"></div>

        <div class="skill-container">
          <div class="name-holder">C#</div>

          <div class="skill csharp">
            <div class="percent-holder"></div>
          </div>
        </div>

        <div class="skill-position"></div>

        <div class="skill-container">
          <div class="name-holder">Java</div>

          <div class="skill java">
            <div class="percent-holder"></div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default SkillBars;
