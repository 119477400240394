import "./TitlesAnimation.css";

function TitlesAnimation() {
  return (
    <p>
        <span id="spin"></span>
    </p>
  );
}

export default TitlesAnimation;
