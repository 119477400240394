import react from "react";
import "./Parallax.css";
import ContentRows from "./ContentRows";
import { Container, Row, Col } from "react-bootstrap";
import background from "../img/citybackgroundgreen.png";
import citymiddle from "../img/citymiddlegreen.png";
import cityfront from "../img/cityfrontgreen.png";
import SideNav from "./SideNav"

function Parallax() {
  return (
    <div>
      <div class="parallax-container">
        {/* <ContentRows/> */}

        <div class="parallax-layer parallax-layer-background">
          <img src={background} />
        </div>

        <div class="parallax-layer parallax-layer-midback">
          <img src={citymiddle} />
        </div>

        <div class="parallax-layer parallax-layer-midfront">
          <img src={cityfront} />
        </div>
        <div class="parallax-layer parallax-layer-front">
          <ContentRows />
        </div>
      </div>
    </div>
  );
}

export default Parallax;
