import "./LinkCards.css";
import { Container, Col, Row } from "react-bootstrap";

function LinkCards() {
  return (
    <div id="body">
      <Row>
        {" "}
        <Col>
          {" "}
          <a href="https://github.com/JerelEsmende">
            {" "}
            <div class="cardbox">
              <div class="card-back"></div>

              <div class="card github">
                <div class="card-front"></div>
                <div class="card-back"></div>
              </div>
            </div>
          </a>
        </Col>
        <Col>
          {" "}
          <a href="https://linkedin.com/in/jerel-esmende">
            {" "}
            <div class="cardbox">
              <div class="card-back"></div>

              <div class="card linkedin">
                <div class="card-front"></div>
                <div class="card-back"></div>
              </div>
            </div>
          </a>
        </Col>
        <Col>
          {" "}
          <a href="https://codepen.io/jerelesmende">
            <div class="cardbox">
              <div class="card-back"></div>

              <div class="card codepen">
                <div class="card-front"></div>
                <div class="card-back"></div>
              </div>
            </div>
          </a>
        </Col>


        <Col>
          {" "}
          <a href="https://codepen.io/jerelesmende">
            <div class="cardbox">
              <div class="card-back"></div>

              <div class="card gmail">
                <div class="card-front"></div>
                <div class="card-back"></div>
              </div>
            </div>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default LinkCards;
