import "./CardTemplate.css";
import photo from "module";
import stock from "../img/stock.png";
import kaiju from "../img/kaiju.png";
import { Container, Row, Col } from "react-bootstrap";

function CardTemplate(props) {
  return (
    <div class="wrapper">
      <div class="card-container">
        <section class="collection-card">
          <div class="cartridge">
            {" "}
            <div class="card-img">
              <a href={props.link}>
                <img
                  class="photo"
                  src={props.image}
                  alt="Unsplash Photo"
                  width="370"
                />
              </a>
            </div>
          </div>

          <div class="card-text">
            <Row>
              <h5 class="title">{props.title}</h5>
            </Row>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CardTemplate;
