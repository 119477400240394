import "./AboutBox.css";
import React, { useState } from "react";
import portrait2 from "../img/mgs-jerel2.png";

import SkillBars from "./SkillBars";
import LogoSlide from "./LogoSlide";
import { Container, Col, Row } from "react-bootstrap";
import sdsu from "../img/gsdsulogo.png";
import cdt from "../img/cdtlogo.png";
import nha from "../img/nha.png";


import html from "../img/glogohtml.png";
import js from "../img/glogojs.png";
import css from "../img/glogocss.png";

import cpp from "../img/glogocpp.png";
import csh from "../img/glogocsharp.png";
import clogo from "../img/glogoc.png";
import py from "../img/glogopy.png";
import java from "../img/glogojava.png";

import rlogo from "../img/glogoreact.png";
import git from "../img/glogogit.png";
import aws from "../img/glogoaws.png";

import award from "../img/Award.png";

import CourseCard from "./CourseCard";
import StartAni from "./StartAnimation";

function LangBox() {
  // ----------------------------------------------------------------------------------------
  return (
    <div>
      <div class="header-space">
        <div class="header-text">. / A b o u t - M e</div>
      </div>

      <div class="about-container">
        <Container>
          <Row>
            <Row>
              <div class="switch-container"></div>
            </Row>

            <Row>
              <div class="content-container">
                <div class="about-text-container">
                  <div class="sub-header">Stat-Sheet</div>

                  <div className="text-holder">
                    <p>
                      My name is Jerel Esmende. A full-stack software engineer/
                      web developer located in San Diego, CA. I am a creative
                      person. Detail oriented. Interested in Web developement
                      using the React Framework to create web API's and have
                      been honing this aspect since 2021.
                    </p>

                    <p>
                      I've worked with a wide variety of programming languages.
                      For web applications I mainly use Javascript/JSX. Have
                      worked with AWS technologies such as: Amplify, S3,
                      Lambdas, EC2 and DynamoDB.
                    </p>
                  </div>
                </div>

                <Row>
                  <Col xs={5}>
                    <div class="main-content">
                      <div className="skillholder">
                        <div className="portrait">
                          <img src={portrait2} />
                        </div>
                        <div className="left-container">
                          {" "}
                          <div className="skill-header">
                            <h4>Languages</h4>
                          </div>
                          <SkillBars />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div className="lists-container">
                      <div className="lists-container-content">
                        <Container>
                          {" "}
                          <Row>
                            {" "}
                            <Col xs="auto">
                              {" "}
                              <h5>Frameworks</h5>
                              <div className="lists-content-l">
                                <p>React.js</p>
                                <p>Next.js</p>
                                <p>Gatsby.js</p>
                                <p>Node.js</p>
                              </div>
                            </Col>
                            <Col xs="auto">
                              {" "}
                              <h5>AWS Cloud Services</h5>
                              <div className="lists-content-mid">
                                <p>Amplify</p>
                                <p>S3</p>
                                <p>Lambdas</p>
                                <p>EC2</p>
                              </div>
                            </Col>
                            <Col xs="auto">
                              {" "}
                              <h5>Software/Engines</h5>
                              <div className="lists-content-r">
                                <p>Linux</p>
                                <p>Windows</p>
                                <p>Unity</p>
                                <p>Adobe Suite</p>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                    <div></div>
                  </Col>

                  <Row></Row>

                  {/*---------------------education----------------------*/}

                  <div class="sub-header-education">
                    <div class="sub-header-text">Education</div>
                  </div>

                  <div class="education-container">
                    <div class="education-content">
                      <Row>
                        <Col xs={2}>
                          <div class="possdsu">
                            <div class="sdsubox">
                              <img src={sdsu} />
                            </div>
                          </div>
                        </Col>
                        <Col xs={10}>
                          <h5>Graduated from San Diego State University</h5>
                          <h5>B.S. in Computer Science</h5>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  {/*---------------------Course Work----------------------*/}

                  <Container>
                    <Row>
                      <Col xs={6}>
                        {" "}
                        <div class="sub-header-achievements">
                          <div class="sub-header-text">Affiliations</div>
                        </div>
                        <div class="pos-course-container">
                          <div class="affiliations-container">
                            <div class="cdt-card">
                              <img src={cdt}></img>
                              <h5>Cyber Defense Team</h5>
                              <p>Member From 2018-2020</p>
                              <p>
                                Knowledge of Nmap, Metasploit and cryptography
                                techniques
                              </p>
                            </div>
                            <div class="cdt-card">
                              <img src={nha}></img>
                              <h5>Neighborhood House Association</h5>
                              <p>Member From 2020-2021</p>
                              <p>
                                Accounting Technician - recorded and logged assets for fiscal review
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <div class="sub-header-achievements">
                          <div class="sub-header-text">Related Course Work</div>
                        </div>
                        <div class="pos-course-container">
                          <div class="course-container">
                            <Row>
                              <Col>
                                {" "}
                                <CourseCard></CourseCard>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>

                  <div class="logo-container">
                    <Container>
                      <Row>
                        {" "}
                        <Col>
                          <div className="logobox">
                            <img src={cpp} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={csh} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={clogo} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={js} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={css} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={css} />
                          </div>
                        </Col>
                        <Col>
                          {" "}
                          <div className="logobox">
                            <img src={rlogo} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={git} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={aws} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={py} />
                          </div>
                        </Col>
                        <Col>
                          <div className="logobox">
                            <img src={java} />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  {/*---------------------Achievements----------------------*/}

                  <div class="sub-header-achievements">
                    <div class="sub-header-text">Achievements</div>
                  </div>

                  <div class="achievements-container">
                    <div class="achievements-content">
                      {" "}
                      <Row>
                        <div className="achievements-text-holder">
                          <Row>
                            {" "}
                            <Col>
                              {" "}
                              <div class="back-img-container">
                                {" "}
                                <h5>National Cyber League Team Game</h5>
                                <Row>
                                  <Col>
                                    {" "}
                                    <p>Competed Silver League</p>
                                    <p>
                                      Worked in teams and collaborated to solve
                                      problems pertaining to cyber security
                                    </p>
                                    <p>
                                      Subjects include open source intelligence,
                                      cryptography, password cracking,
                                      exploitation methods, log and network
                                      traffic Analysis
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col>
                              {" "}
                              <div class="back-img-container2">
                                {" "}
                                <h5>
                                  Thinking Outside the Potato, SDSU Zahn
                                  Challenge
                                </h5>
                                <Row>
                                  <Col>
                                    {" "}
                                    <p>
                                      Problem: Transform the Mr/Mrs. Potato Head
                                      into a tool, device and can be used in
                                      daily life
                                    </p>
                                    <p>
                                      Submission: Wake potato – A Mr.
                                      Potato-Head alarm clock that shuts off
                                      once ejected pieces are put back together.
                                    </p>
                                    <p>
                                      Worked on the programing for the Arduino
                                      and launching mechanism for the clock
                                    </p>
                                    <p>
                                      {" "}
                                      Results: 1st in Category - Technically
                                      Ingenious
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col>
                              {" "}
                              <div class="back-img-container3">
                                {" "}
                                <h5> Clean Transportation Challenge</h5>
                                <Row>
                                  {" "}
                                  <p>
                                    Problem: Collaborate with a team and present
                                    a professional presentation on innovative
                                    ideas to improve public greener forms of
                                    transportation.
                                  </p>
                                  <p>
                                    Proposed – Rapid Express electric/hybrid
                                    Buses with more bike racks to promote green
                                    transportation.
                                  </p>
                                  <p>
                                    Researched data for green transportation and
                                    collected testimonials from commuters
                                  </p>
                                  <div class="img-pos">
                                    {" "}
                                    <img src={award}></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <div id="spacer-projects"></div>
                </Row>
              </div>
            </Row>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          {/* </Col> */}
        </Container>
      </div>
    </div>
  );
}

export default LangBox;
