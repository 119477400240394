import "./App.css";
import SideNav from "./components/SideNav";
import Parallax from "./components/Parallax";
import ContentRows from "./components/ContentRows";

import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <div>
      {/* <SideNav /> */}
      <div class="offset-main">
        <Parallax />
      </div>
    </div>
  );
}

export default App;