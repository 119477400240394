import "./Tetris.css";
import skew from "../img/skew.png";
import zskew from "../img/zskew.png";
import lshape from "../img/lshape.png";
import tshape from "../img/tshape.png";
import rshape from "../img/rshape.png";
import square from "../img/square.png";
import line from "../img/line.png";



function Tetris() {
  return (
    <body>
      <section>
        <div class="caption"></div>
        <div class="block-container">
          <i class="block">
            <img src={skew}></img>
          </i>
          <i class="block">
            <img src={tshape}></img>
          </i>
          <i class="block">
            <img src={rshape}></img>
          </i>
          <i class="block">
            <img src={zskew}></img>
          </i>
          <i class="block">
            <img src={square}></img>
          </i>
          <i class="block">
            <img src={line}></img>
          </i>
          <i class="block">
            <img src={skew}></img>
          </i>
          <i class="block">
            <img src={zskew}></img>
          </i>
          <i class="block">
            <img src={tshape}></img>
          </i>
          <i class="block">
            <img src={lshape}></img>
          </i>

        
        </div>
      </section>
    </body>
  );
}

export default Tetris;
