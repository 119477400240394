import "./ProjectBox.css";
import ProjectCard from "./CardTemplate";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import room from "../img/tetraminoes.png";
import stock from "../img/stock.png";
import kaiju from "../img/kaiju.png";
import gb from "../img/BasicGameBoyV3.png";

function ProjectBox() {
  const cardData = [
    {
      id: "e1",
      title: "Stock Scraper",
      link: "http://static-stock-site-jesmende.s3-website-us-east-1.amazonaws.com/",
      image: stock,
      description:
        "Yahoo stock scrapper that displays that dynamically displays current stock values utiliziing AWS services: S3 and Lambdas ",
    },
    {
      id: "e2",
      title: "Kaiju Fighting Game",
      link: "https://github.com/JerelEsmende/kaiju_game",
      image: kaiju,
      description: "for testing purposes only",
    },
    {
      id: "e3",
      title: "other",
      link: "https://github.com/JerelEsmende/kaiju_game",
      image: gb,
      description: "for testing purposes only",
    },
    {
      id: "e4",
      title: "Microshells",
      image: room,
      description: "for testing purposes only",
    },
    {
      id: "e5",
      title: "Reactive LED Visualizer",
      image: room,
      description: "for testing purposes only",
    },

    {
      id: "e6",
      title: "Mr.Potato Head Alarm Clock",
      image: room,
      description: "for testing purposes only",
    },
  ];

  //------------------------------------------------

  return (
    <div>
      <Row>
        <div class="room-container">
          <div class="room-image">
            <div class="cartridge-container">
              <div>
                <div>
                  <Row>
                    {" "}
                    <Col>
                      {" "}
                      <ProjectCard
                        title={cardData[0].title}
                        link={cardData[0].link}
                        image={cardData[0].image}
                        description={"Description: " + cardData[0].description}
                      />
                    </Col>
                    <Col>
                      <ProjectCard
                        title={cardData[1].title}
                        link={cardData[1].link}
                        image={cardData[1].image}
                        description={"Description: " + cardData[0].description}
                      />
                    </Col>
                    <Col>
                      {" "}
                      <ProjectCard
                        title={cardData[2].title}
                        link={cardData[2].link}
                        image={cardData[2].image}
                        description={"Description: " + cardData[0].description}
                      />
                    </Col>
                    <Col>
                      <ProjectCard
                        title={cardData[3].title}
                        link={cardData[3].link}
                        image={cardData[3].image}
                        description={"Description: " + cardData[0].description}
                      />
                    </Col>
                    <Col>
                      {" "}
                      <ProjectCard
                        title={cardData[4].title}
                        link={cardData[4].link}
                        image={cardData[4].image}
                        description={"Description: " + cardData[0].description}
                      />
                    </Col>
                    <Col>
                      <ProjectCard
                        title={cardData[5].title}
                        link={cardData[5].link}
                        image={cardData[5].image}
                        description={"Description: " + cardData[0].description}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <div class="adjustment-space"></div>
      </Row>
    </div>
  );
}
export default ProjectBox;
