import "./Screen.css";
import React, { useState } from "react";
import TitleAni from "./TitlesAnimation";
import StartAni from "./StartAnimation";
import Gamebutton from "./GameButton";
import PDF from "../files/Resume-1.pdf";

function Screen() {
  const [checked, setChecked] = useState(false);
  const toggleChecked = () => setChecked((value) => !value);

  function toggleHide() {
    toggleChecked();
    hideIt();
  }

  function toggleShow() {
    toggleChecked();
    showIt();
  }

  function hideIt() {
    document.getElementById("screen_label").style.zIndex = "-50";
  }

  function showIt() {
    document.getElementById("screen_label").style.zIndex = "100";
  }

  function onResumeClick() {
    window.open(PDF);
  }
  

  let spawnButtons;
  if (checked == true) {
    hideIt();
    spawnButtons = <Gamebutton></Gamebutton>;
  }

  return (
    <div>
      <div id="wrapper">
        <div id="menu_screen">
          <input type="checkbox" id="menu_checkbox" />

          <div for="menu_checkbox" id="menu_label">
            <a id="bot_bar"></a>

            <div href="#start" id="menu_text_bar" />

            <a href="#spacer-about" id="about_text_bar"></a>

            <a href="#spacer-projects" id="bio_text_bar"></a>

            <a href="#spacer-links" id="link_text_bar"></a>

            <div id="resume_button">
                <div class="resume" />
            </div>

            <div id="fname_bar" />

            <div id="lname_bar" />
            <div id="top_bar">
              <TitleAni />
            </div>
            <div id="divider_bar"></div>
            <div id="portrait_cover">
              <div className="start_holder">
                <StartAni />
              </div>
            </div>
          </div>
        </div>
      </div>
      <label
        for="menu_checkbox"
        id="start_label"
        checked={checked}
        onClick={toggleShow}
      ></label>

      <div>{spawnButtons}</div>
      <label
        for="menu_checkbox"
        id="screen_label"
        checked={checked}
        onClick={toggleHide}
      ></label>
    </div>
  );
}

export default Screen;
