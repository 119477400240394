import "./StartAnimation.css";

function StartAnimation() {
  return (
    <p>
        <span id="change"></span>
    </p>
  );
}

export default StartAnimation;
